<template>
    <div class="BrandEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="brand" label-width="80px" :model="brand" @submit.native.prevent :rules="rules" size="small">
                <el-form-item label="编码" prop="code">
                    <el-input readonly v-model="brand.code" />
                </el-form-item>
                <el-form-item label="名称" prop="name">
                    <el-input v-model="brand.name" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="save" size="small">保存</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from 'js/UrlUtils';

export default {
    name: 'BrandEdit',
    props: ['form'],

    data() {
        return {
            brand: {},
            backUp: {
                code: '',
                name: {},
            },
            rules: {
                code: [{ required: true, message: '编码必填', trigger: 'blur' }],
                name: [{ required: true, message: '请填写品牌名称', trigger: 'blur' }],
            },
        };
    },

    mounted() {
        this.backUp.code = this.form.code;
        this.backUp.name = this.form.name;
        this.brand = JSON.parse(JSON.stringify(this.backUp));
    },
    methods: {
        save() {
            const _this = this;
            this.$refs['brand'].validate((valid) => {
                if (!valid) return;
                UrlUtils.PatchRemote(this, '/goods/brand/patch_c/' + _this.brand.code, _this.brand, null, () => {
                    _this.$message.success('操作成功');
                    this.goBackAndReload();
                });
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
